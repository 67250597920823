import request from './request'

export default {
    /**
     * 查询资料
     * @returns {AxiosPromise}
     */
    getMaterialPage(data) {
        return request({
            url: '/material/page',
            method: 'post',
            data: data
        })
    },
    /**
     * 生成测验
     * @returns {AxiosPromise}
     */
    generateQuiz(data) {
        return request({
            url: '/interview/generateQuiz',
            method: 'post',
            data: data
        })
    },
    /**
     * 提交测验
     * @returns {AxiosPromise}
     */
    submitTest(data) {
        return request({
            url: '/interview/submitTest',
            method: 'post',
            data: data
        })
    },
    /**
     * 测验记录分页查询
     * @returns {AxiosPromise}
     */
    answerRecordPage(data) {
        return request({
            url: '/interview/answerRecordPage',
            method: 'post',
            data: data
        })
    },
    /**
     * 测验记录详情
     * @returns {AxiosPromise}
     */
    answerRecordDetails(data) {
        return request({
            url: '/interview/answerRecordDetails',
            method: 'post',
            data: data
        })
    },
    /**
     * 错误记录分页查询
     * @returns {AxiosPromise}
     */
    errorRecorPage(data) {
        return request({
            url: '/interview/errorRecorPage',
            method: 'post',
            data: data
        })
    },
    /**
     * 删除错误记录
     * @returns {AxiosPromise}
     */
    errorRecorRemove(data) {
        return request({
            url: '/interview/errorRecorRemove',
            method: 'post',
            data: data
        })
    }
}
