<template>
  <div style="margin-top: 20px;margin-left: 20px;">
    <el-empty v-if="!loginCheck" description="暂无数据"></el-empty>
    <div v-if="loginCheck">
      <el-row>
        <el-col :span="24" v-if="!testContent">
          <div>
            <el-form :inline="true" :model="queryForm" class="demo-form-inline">
              <el-form-item label="种类">
                <el-select v-model="queryForm.speciesId" placeholder="请选择">
                  <el-option
                      v-for="item in speciesOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="类型">
                <el-select v-model="queryForm.type" placeholder="请选择">
                  <el-option
                      v-for="item in typeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="生成个数" v-if="queryForm.type===2">
                <el-select v-model="queryForm.numberTpye" placeholder="请选择">
                  <el-option
                      v-for="item in numberTpyeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="难度" v-if="queryForm.type===1">
                <el-select v-model="queryForm.difficulty" placeholder="请选择">
                  <el-option
                      v-for="item in numberOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="题目数量" v-if="queryForm.type===1 || queryForm.numberTpye===1">
                <el-input-number v-model="queryForm.number" :min="10" :max="200" :step="5"></el-input-number>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="generateQuiz()">生成</el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="24" v-if="testContent">
          <div>
            <el-card class="box-card" shadow="hover">
              <div style="display: flex;justify-content: space-between;">
                <div style="font-size: 20px;">在线测试</div>
                <div style="font-size: 20px;">{{ timerStr }}</div>
              </div>

              <el-divider></el-divider>
<!--              <div @click="endTiming()">停止</div>-->
<!--              <div @click="startTiming()">继续</div>-->
              <el-row>
                <el-col :span="24">
                  <el-tag type="info">{{ dataDetails.number }}/{{ data.length }}</el-tag>
                  <span v-if="dataDetails.topicType===1" style="margin-left: 10px;">[判断题]</span>
                  <span v-if="dataDetails.topicType===2" style="margin-left: 10px;">[单选题]</span>
                  <span v-if="dataDetails.topicType===3" style="margin-left: 10px;">[填空题]</span>
                  <span v-if="dataDetails.topicType===4" style="margin-left: 10px;">[应用题]</span>
                  <span v-if="dataDetails.topicType===5" style="margin-left: 10px;">[多选题]</span>
                </el-col>
                <el-col :span="24">
                  <div style="margin-top: 20px; margin-left: 10px;" v-html="dataDetails.title"></div>
                </el-col>
                <el-col :span="24">
                  <div>
                    <el-radio-group v-if="dataDetails.topicType===2" v-model="dataDetails.options" @change="operate">
                      <el-radio v-for="it in dataDetails.optionsList" :key="it.id" :label="it.id" style="width: 100%;margin-left: 10px;margin-top: 10px;white-space: normal;">{{ it.content }}</el-radio>
                    </el-radio-group>
                    <el-checkbox-group v-if="dataDetails.topicType===5" v-model="optionsArr" @change="operate">
                      <el-checkbox v-for="it2 in dataDetails.optionsList" :key="it2.id" :label="it2.id" style="width: 100%;margin-left: 10px;margin-top: 10px;white-space: normal;">{{ it2.content }}</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div style="margin-top: 20px;display: flex;justify-content: flex-end;">
                    <el-button-group>
                      <el-button v-if="dataDetails.number!==1" @click="previousQuestion()" type="primary" icon="el-icon-arrow-left">上一题</el-button>
                      <el-button v-if="dataDetails.number!==data.length" @click="nextQuestion()" type="primary">下一题<i class="el-icon-arrow-right el-icon--right"></i></el-button>
                    </el-button-group>
                    <el-button @click="submitTest()" type="success" style="margin-left: 10px;">提交</el-button>
                  </div>
                </el-col>
                <el-col :span="24">
                  <el-divider></el-divider>
                </el-col>
                <el-col :span="24">
                  <div>
                    答题卡
                  </div>
                </el-col>
                <el-col :span="24">
                  <div style="margin-top: 10px;height: 200px;overflow: auto;border: #DCDFE6 solid 1px;">
                    <el-tag
                        v-for="it in items"
                        :key="it.id"
                        :type="it.type"
                        :effect="it.plain"
                        @click="answerSheet(it)"
                        v-bind:class="{ segmentation: it.segmentation}">
                      {{ it.number }}
                    </el-tag>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import token from "@/api/token"
import interview from "@/api/interview"
export default {
  name: "Test",
  data() {
    return {
      loginCheck: false,
      queryForm: {
        number: 10,
        difficulty: 1,
        type: 1,
        numberTpye: 1,
        speciesId: 2
      },
      numberOptions: [
        {
          value: 1,
          label: '简单'
        },
        {
          value: 2,
          label: '一般'
        },
        {
          value: 3,
          label: '困难'
        }
      ],
      typeOptions: [
        {
          value: 1,
          label: '新的测验'
        },
        {
          value: 2,
          label: '错题重测'
        }
      ],
      numberTpyeOptions: [
        {
          value: 1,
          label: '指定个数'
        },
        {
          value: 2,
          label: '全部'
        }
      ],
      speciesOptions: [
        {
          value: 2,
          label: 'PMP'
        }
      ],
      radio: null,
      items: [],
      data: [],
      dataDetails: {},
      answerQuestions: [],
      testContent: false,
      timer: 1,
      timerStr: '1秒',
      setInterval: null,
      optionsArr:[],
      loading: null,
      loading2: null
    }
  },
  created() {
    if (token.getToken()) {
      this.loginCheck = true
    } else {
      this.$message({
        message: '请登录后，在进行操作',
        type: 'warning'
      })
    }
  },
  methods: {
    /**
     * 生成测试
     */
    generateQuiz() {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.optionsArr = []
      this.timer = 1
      this.timerStr = '1秒'
      interview.generateQuiz(this.queryForm).then(res => {
        this.data = res.data
        this.dataDetails  = res.data[0]
        for (let i = 0; i < res.data.length; i++) {
          this.data[i].number = i + 1
          if (i === 0) {
            this.items.push({
              index: i,
              number: i + 1,
              type: 'info',
              id: res.data[i].id,
              effect: 'plain',
              segmentation: true
            })
          } else {
            this.items.push({
              index: i,
              number: i + 1,
              type: 'info',
              id: res.data[i].id,
              effect: 'plain',
              segmentation: true
            })
          }
        }
        this.testContent = true
        this.setInterval = setInterval(this.timing,1000)
        this.loading.close()
      }, error => {
        this.loading.close()
      })

    },
    /**
     * 根据用户选择，跳转对应的题目
     */
    answerSheet(row) {
      this.dataDetails = this.data[row.index]
      this.dataDetails.number = row.number
      if (this.answerQuestions.length > 0) {
        for (let i = 0; i < this.answerQuestions.length; i++) {
          if (this.answerQuestions[i].topicId == this.dataDetails.id) {
            this.optionsArr = this.answerQuestions[i].optionsId
          }
        }
      }
    },
    /**
     * 操作题目
     */
    operate(id) {
      console.log(this.optionsArr)
      // 本题目从答题卡中移除
      if (this.answerQuestions.length > 0) {
        for (let i = 0; i < this.answerQuestions.length; i++) {
          if (this.dataDetails.id === this.answerQuestions[i].topicId) {
            this.answerQuestions.splice(i, 1)
          }
        }
      }
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].id === this.dataDetails.id) {
          // 设置本题已做
          this.items[i].type = ''
          let data = []
          if (Array.isArray(id)) {
            data = id
          } else {
            data.push(id)
          }
          // 本题目添加到答题卡中
          this.answerQuestions.push({
            topicId: this.dataDetails.id,
            optionsId: data
          })
          console.log(this.answerQuestions)
        }
      }
    },
    /**
     * 上一题
     */
    previousQuestion() {
      if (this.dataDetails.number !== 1) {
        this.dataDetails = this.data[this.dataDetails.number - 2]
        this.optionsArr = []
        if (this.answerQuestions.length > 0) {
          for (let i = 0; i < this.answerQuestions.length; i++) {
            if (this.answerQuestions[i].topicId == this.dataDetails.id) {
              this.optionsArr = this.answerQuestions[i].optionsId
            }
          }
        }
      }
    },
    /**
     * 下一题
     */
    nextQuestion() {
      if (this.dataDetails.number !== this.data.length) {
        this.dataDetails = this.data[this.dataDetails.number]
        this.optionsArr = []
        if (this.answerQuestions.length > 0) {
          for (let i = 0; i < this.answerQuestions.length; i++) {
            if (this.answerQuestions[i].topicId == this.dataDetails.id) {
              this.optionsArr = this.answerQuestions[i].optionsId
            }
          }
        }
      }
    },
    /**
     * 提交测验
     */
    submitTest() {
      if (this.answerQuestions.length != this.data.length) {
        this.$message({
          message: '还有题目未做完',
          type: 'warning'
        })
        return
      }
      this.loading2 = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.endTiming()
      let name = ''
      if (this.queryForm.type == 1) {
        name = this.typeOptions[this.queryForm.type - 1].label + '-' + this.numberOptions[this.queryForm.difficulty - 1].label + '-' + this.queryForm.number + '题'
      } else {
        if (this.queryForm.numberTpye === 1) {
          name = this.typeOptions[this.queryForm.type - 1].label + '-' + this.numberTpyeOptions[this.queryForm.numberTpye - 1].label + '-' + this.queryForm.number + '题'
        } else {
          name = this.typeOptions[this.queryForm.type - 1].label + '-' + this.numberTpyeOptions[this.queryForm.numberTpye - 1].label
        }
      }
      console.log({
        list: this.answerQuestions,
        name: name,
        duration: this.timerStr,
        durationSecond: this.timer
      })
      interview.submitTest({
        list: this.answerQuestions,
        name: name,
        duration: this.timerStr,
        durationSecond: this.timer
      }).then(res => {
        if (res.code === 200) {
          this.message(res.message)
          this.testContent = false
          this.items = []
          this.data = []
          this.dataDetails = {}
          this.answerQuestions = []
          this.timer = 1
          this.timerStr = '1秒'
          this.optionsArr = []
          this.loading2.close()
        }
      }, error => {
        this.loading2.close()
      })
    },
    /**
     * 消息提示
     * @param code
     * @param message
     */
    message(message) {
      this.$message({
        message: message,
        type: 'success'
      })
    },
    /**
     * 开始计时
     */
    startTiming(){
      if (!this.setInterval) {
        this.setInterval = setInterval(this.timing,1000)
      }
    },
    /**
     * 停止计时
     */
    endTiming(){
      clearInterval(this.setInterval)
      this.setInterval = null
    },
    /**
     * 计时器方法
     */
    timing() {
      this.timer += 1
      // 时
      let hour = parseInt(this.timer / 3600)
      // 分
      let minute = 0
      // 秒
      let second = 0
      if (hour > 0) {
        minute = parseInt((this.timer - (hour * 3600)) / 60)
        second = this.timer - (hour * 3600) - (minute * 60)
      } else {
        minute = parseInt(this.timer / 60)
        second = this.timer - (minute * 60)
      }
      if (hour > 0) {
        this.timerStr = hour + '时' + minute + '分' + second + '秒'
      } else if (this.timer >= 60) {
        this.timerStr = minute + '分' + second + '秒'
      } else {
        this.timerStr = this.timer + '秒'
      }
    }
  }
}
</script>

<style scoped>
.segmentation{
  margin: 5px;
}
</style>
